<template>
  <div class="table-responsive">
    <table class="table m-table table-hover m-table--head-separator-primary">
      <thead>
        <tr>
          <th v-if="columns.length" v-for="(column, index) in columns" :key="index">{{ column }}</th>
        </tr>
      </thead>
        <tbody>
          <slot></slot>
        </tbody>
    </table>
  </div>
</template>

<script>
export default {
    name: 'UTable',
    props: ['columns']
}
</script>
