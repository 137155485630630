<template>
    <div class="m-portlet m-portlet--mobile m-portlet--body-progress-" style="">
        <slot name="header" v-if="header">
            <div class="m-portlet__head">
                <div class="m-portlet__head-caption">
                    <div class="m-portlet__head-title">
                        <h3 class="m-portlet__head-text">
                            {{ title }}
                        </h3>
                    </div>
                </div>
                <div class="m-portlet__head-tools">
                    <slot name="tools"></slot>
                </div>
            </div>
        </slot>
        <div class="m-portlet__body">
            <slot></slot>
        </div>
    </div>
</template>
<script>
export default {
  name: 'u-portlet',
  props: {
    header: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: '',
      required: false
    },
  }
}
</script>