<template>
  <div v-if="!isLoading" id="usage">
    <header-service
      :service="service"
      currentTab="Uso"
      :isSaving="!isLoading && isSaving"
      @saving="save"
      :files="files"
      @resetFiles="resetFiles"
    ></header-service>
    <div class="row mt-5">
      <div class="col-lg-3 col-md-4">
        <menu-service :service="service"></menu-service>
      </div>

      <div class="col-lg-9 col-md-8" ref="form">
        <u-portlet title="Canais em que o serviço está disponível para uso">
          <div class="col-md-12">
            <div class="form-row">
              <div
                v-for="(channel, index) in channels"
                :key="index"
                class="form-group col-md-2"
              >
                <div class="mb-2">
                  <div class="custom-control custom-checkbox mb-4">
                    <input
                      :disabled="inputDisable"
                      type="checkbox"
                      class="custom-control-input"
                      :checked="checkChannel(channel.id)"
                      :id="`channel-${index}`"
                      @click="toogleChannel($event.target)"
                      :data-channel="`${channel.id}`"
                    />
                    <label
                      class="custom-control-label"
                      :for="`channel-${index}`"
                    >
                      {{ channel.name }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </u-portlet>
        <!-- END CHANNELS -->

        <!-- OP SYSTEMS -->
        <u-portlet v-if="showSystemOp" title="Sistemas operacionais">
          <div class="col-md-12">
            <div class="form-row">
              <div
                v-for="(opSystem, index) in operationSystems"
                :key="index"
                class="form-group col-md-2"
              >
                <div class="mb-2">
                  <div class="custom-control custom-checkbox mb-4">
                    <input
                      :disabled="inputDisable"
                      type="checkbox"
                      class="custom-control-input"
                      :checked="checkOpSystem(opSystem.id)"
                      @click="toggleOpSystem($event.target)"
                      :id="`opsystem-${index}`"
                      :data-opSystemId="opSystem.id"
                    />
                    <label
                      class="custom-control-label"
                      :for="`opsystem-${index}`"
                    >
                      {{ opSystem.name }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <b-tabs>
            <b-tab
              v-if="usageOperationSystems && usageOperationSystems.length"
              v-for="(opSystem, systemIndex) in usageOperationSystems"
              :key="systemIndex"
              :title="getOpSystemlName(opSystem.operation_system_id)"
              :active="navActive(opSystem.operation_system_id)"
            >
              <input
                :disabled="inputDisable"
                v-if="opSystem.id"
                type="hidden"
                class="usage-opsystem-saved"
                :value="opSystem.id"
              />
              <div class="form-row mt-3">
                <div class="form-group col-md-12">
                  <u-portlet title="Informações do App">
                    <button
                      slot="tools"
                      type="button"
                      class="btn btn-primary btn-sm"
                      data-toggle="modal"
                      data-target="#app-version-store"
                      @click="setNewAppVersion(opSystem.operation_system_id)"
                    >
                      <i class="fa fa-plus"></i>
                      Nova versão
                    </button>
                    <u-table
                      v-if="opSystem.info && opSystem.info.length"
                      :columns="systemColumns"
                    >
                      <tr
                        v-for="(info, infoIndex) in opSystem.info"
                        :key="infoIndex"
                      >
                        <td class="pt-4 pb-4">{{ info.version }}</td>
                        <td class="pt-4 pb-4">{{ info.build_link }}</td>
                        <td class="pt-4 pb-4">{{ info.store_link }}</td>
                        <td width="170" class="pt-4">
                          <a
                            href="javascript:;"
                            class="mr-5"
                            data-toggle="modal"
                            title="Visualizar"
                            :data-target="
                              `#app-version-update-${systemIndex}-${infoIndex}`
                            "
                          >
                            <i class="fa flaticon-cogwheel-2"></i>
                          </a>
                          <a
                            href="javascript:;"
                            title="Excluir"
                            @click="
                              () =>
                                usageOperationSystems[systemIndex].info.splice(
                                  infoIndex,
                                  1
                                )
                            "
                          >
                            <i class="fa flaticon-delete"></i>
                          </a>
                        </td>
                        <input
                          :disabled="inputDisable"
                          v-if="info.id"
                          type="hidden"
                          class="app-info-saved"
                          :value="info.id"
                        />
                        <!-- MODAL UPDATE VERSION APP -->
                        <u-modal
                          :id="`app-version-update-${systemIndex}-${infoIndex}`"
                          title="Atualizar Informações do App"
                          size="lg"
                        >
                          <div slot="body">
                            <div class="form-group m-form__group row">
                              <div class="col-lg-2">
                                <label>Versão</label>
                                <input
                                  :disabled="inputDisable"
                                  type="text"
                                  @keyup.enter="
                                    dismissModal('#app-version-update')
                                  "
                                  v-model="
                                    usageOperationSystems[systemIndex].info[
                                      infoIndex
                                    ].version
                                  "
                                  class="form-control m-input"
                                />
                              </div>
                              <div class="col-lg-5">
                                <label>Link Build</label>
                                <input
                                  type="text"
                                  @keyup.enter="
                                    dismissModal('#app-version-update')
                                  "
                                  v-model="
                                    usageOperationSystems[systemIndex].info[
                                      infoIndex
                                    ].build_link
                                  "
                                  class="form-control m-input"
                                />
                              </div>
                              <div class="col-lg-5">
                                <label>Link Loja</label>
                                <input
                                  type="text"
                                  @keyup.enter="
                                    dismissModal('#app-version-update')
                                  "
                                  v-model="
                                    usageOperationSystems[systemIndex].info[
                                      infoIndex
                                    ].store_link
                                  "
                                  class="form-control m-input"
                                />
                              </div>
                            </div>
                            <div class="form-group m-form__group row">
                              <div class="col-lg-12">
                                <label>Descrição</label>
                                <textarea
                                  :disabled="inputDisable"
                                  @keyup.enter="
                                    dismissModal('#app-version-update')
                                  "
                                  v-model="
                                    usageOperationSystems[systemIndex].info[
                                      infoIndex
                                    ].description
                                  "
                                  class="form-control"
                                ></textarea>
                              </div>
                            </div>
                          </div>
                          <div slot="footer">
                            <button
                              :disabled="inputDisable"
                              type="button"
                              class="btn btn-secondary"
                              data-dismiss="modal"
                            >
                              Fechar
                            </button>
                          </div>
                        </u-modal>
                      </tr>
                    </u-table>
                  </u-portlet>
                  <label>Descrição/Script de Uso</label>
                  <textarea
                    :disabled="inputDisable"
                    class="form-control mb-3"
                    rows="5"
                    v-model="usageOperationSystems[systemIndex].description"
                  ></textarea>
                  <label
                    class="btn btn-outline-primary btn-upload"
                    :for="`opsystem-file-${opSystem.operation_system_id}`"
                    title="Upload image file"
                  >
                    <input
                      :disabled="inputDisable"
                      type="file"
                      class="sr-only all-files"
                      @change="addFiles($event.target)"
                      multiple
                      :id="`opsystem-file-${opSystem.operation_system_id}`"
                      :data-id="opSystem.operation_system_id"
                      accept=".jpg,.jpeg,.png,.gif,.bmp,.tiff"
                    />
                    <span
                      class="info-file"
                      :id="`info-file-${opSystem.operation_system_id}`"
                      >Adicionar imagens</span
                    >
                  </label>
                </div>
                <div
                  v-if="opSystem.files && opSystem.files.length"
                  v-for="(file, fileIndex) in opSystem.files"
                  :key="fileIndex"
                  :id="`saved-file-${file.id}`"
                  class="col-xl-3 col-lg-4 col-12 col-sm-6 mb-4 mr-4"
                >
                  <div class="card b-0">
                    <div class="position-relative">
                      <img
                        class="card-img-top"
                        :src="file.url"
                        :alt="file.original_name"
                      />
                      <span
                        @click="deleteFile(systemIndex, fileIndex)"
                        :data-id="`${file.id}`"
                        class="link m-menu__link-badge pull-right"
                      >
                        <span class="m-badge m-badge--primary"> Excluir </span>
                      </span>
                      <input
                        :disabled="inputDisable"
                        v-if="file.id"
                        type="hidden"
                        class="usage-files-saved"
                        :value="file.id"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </u-portlet>

        <u-portlet title="Apresentação técnica do serviço">
          <QuillEditor
            :disabled="inputDisable"
            :placeholder="'listar os conteudos do serviço...'"
            v-model="service"
            :propety="'description_tech'"
          />
        </u-portlet>

        <u-portlet title="Restrições de uso">
          <label class="m-checkbox m-checkbox--success">
            <input
              type="checkbox"
              v-model="service.restrictions_usage"
              :value="1"
              @change="service.restrictions_description = ''"
            />
            Possui restrições de uso?
            <span></span>
          </label>

          <QuillEditor
            class="mt-3"
            v-if="service.restrictions_usage"
            :placeholder="'descreva as restrições...'"
            :disabled="inputDisable"
            v-model="service"
            :propety="'restrictions_description'"
          />
        </u-portlet>

        <u-portlet title="Regras de uso">
          <textarea
            :disabled="inputDisable"
            class="form-control m-input"
            v-model="service.rules_of_use"
            rows="5"
          ></textarea>
        </u-portlet>

        <u-portlet title="Imagens das telas principais">
          <ButtonUploadImages @changeImages="files = $event" />

          <div class="form-group m-form__group row">
            <div class="col-lg-12">
              <label>Passo a passo e ativação</label>
              <QuillEditor
                :disabled="inputDisable"
                :placeholder="'listar os conteudos do serviço...'"
                v-model="service"
                :propety="'step_by_step'"
              />
            </div>
          </div>

          <hr v-if="service.files.length || files.length" />

          <Gallery :files="service.files" :fileList="files" />
        </u-portlet>
        <!-- END OP SYSTEMS -->
      </div>
    </div>

    <!-- MODAL NEW VERSION APP -->
    <u-modal id="app-version-store" title="Informações do App" size="lg">
      <div slot="body">
        <div class="form-group m-form__group row">
          <div class="col-lg-2">
            <label>Versão</label>
            <input
              type="text"
              ref="appNewVersionInput"
              @keyup.enter="addVersionStore"
              v-model="appNewVersion.version"
              class="form-control m-input"
            />
          </div>
          <div class="col-lg-5">
            <label>Link Build</label>
            <input
              type="text"
              @keyup.enter="addVersionStore"
              v-model="appNewVersion.build_link"
              class="form-control m-input"
            />
          </div>
          <div class="col-lg-5">
            <label>Link Loja</label>
            <input
              type="text"
              @keyup.enter="addVersionStore"
              v-model="appNewVersion.store_link"
              class="form-control m-input"
            />
          </div>
        </div>
        <div class="form-group m-form__group row">
          <div class="col-lg-12">
            <label>Descrição</label>
            <textarea
              @keyup.enter="addVersionStore"
              v-model="appNewVersion.description"
              class="form-control"
            ></textarea>
          </div>
        </div>
      </div>
      <div slot="footer">
        <button
          type="button"
          class="btn btn-secondary mr-2"
          data-dismiss="modal"
        >
          Fechar
        </button>
        <button
          type="button"
          class="btn btn-primary"
          data-dismiss="modal"
          @click="addVersionStore"
        >
          Adicionar
        </button>
      </div>
    </u-modal>
  </div>
</template>

<script>
import USubHeader from "@/components/USubHeader";
import UTable from "@/components/UTable";
import UModal from "@/components/UModal";
import MenuService from "../components/MenuService";
import HeaderService from "../components/HeaderService";
import UPortlet from "@/components/UPortlet";
import ServiceService from "../services/ServiceService";
import UsageService from "../services/UsageService";

export default {
  name: "Usage",
  components: {
    USubHeader,
    MenuService,
    UPortlet,
    UTable,
    UModal,
    HeaderService,
    QuillEditor: () => import("@/components/QuillEditor.vue"),
    Gallery: () => import("../components/file/Gallery.vue"),
    ButtonUploadImages: () =>
      import("../components/file/ButtonUploadImages.vue")
  },
  created() {
    this.getService();
  },
  data() {
    return {
      isLoading: true,
      isSaving: false,
      service: {},
      usageChannels: {},
      usageOperationSystems: {},
      serviceService: new ServiceService(),
      usageService: new UsageService(),
      formData: new FormData(),
      files: [],
      inputDisable: !this.$authorization.can("service-update"),
      channels: [
        {
          id: 1,
          name: "Web+Wap"
        },
        {
          id: 2,
          name: "SMS"
        },
        {
          id: 3,
          name: "App"
        },
        {
          id: 4,
          name: "MMS"
        },
        {
          id: 5,
          name: "Portal de Voz"
        }
      ],
      operationSystems: [
        {
          id: 1,
          name: "iOS"
        },
        {
          id: 2,
          name: "Android"
        }
      ],
      systemColumns: [
        "Versão do App",
        "Link Build",
        "Link Loja",
        "Atualizações (Changing)"
      ],
      appNewVersion: {
        id: "",
        version: "",
        build_link: "",
        store_link: "",
        description: ""
      },
      tabActive: 0,
      showSystemOp: false
    };
  },
  watch: {
    "$store.state.service.lock": function(newValue, oldValue) {
      this.lockForm(newValue);
    }
  },
  methods: {
    getService() {
      this.serviceService.getService(this.$route.params.id).then(response => {
        this.service = response.data;
        this.usageChannels = response.data.usageChannels;
        this.usageOperationSystems = response.data.usageOperationSystems;
        this.isLoading = false;
        this.showSystemOperation();
        this.preventEditForm();
      });
    },
    save() {
      if (!this.validator()) {
        return swal("Atenção!", "Detalhe as retrições do uso", "error");
      }

      this.isSaving = true;
      this.setFormDataSaved();

      this.usageService
        .save(this.formData)
        .then(response => {
          this.usageOperationSystems = response.data;
          this.isSaving = false;
          this.getService();
          swal("Sucesso!", "Serviço atualizado com sucesso.", "success");
          if (this.service.status_register === "complete") {
            this.service.status_register = "updating";
          }
        })
        .catch(error => {
          this.isSaving = false;
          swal(
            "Ops!",
            "Dados Inválidos. Verifique os dados informados e tente novamente.",
            "error"
          );
          console.log(error.response);
        });
      this.clearData();
    },
    toogleChannel(element) {
      const channelId = parseInt(element.getAttribute("data-channel"));
      if (!element.checked) {
        this.usageChannels.forEach((channel, index) => {
          if (channel.channel_id === channelId) {
            this.usageChannels.splice(index, 1);
          }
        });

        if (channelId === 3) {
          this.usageOperationSystems = [];
        }
      } else if (!this.checkChannel(channelId)) {
        this.usageChannels.push({
          id: null,
          service_id: this.service.id,
          channel_id: channelId
        });
      }
      this.showSystemOperation();
    },
    toggleOpSystem(element) {
      const opSystemId = parseInt(element.getAttribute("data-opSystemId"));
      this.tabActive = opSystemId;
      if (!element.checked) {
        for (let i in this.usageOperationSystems) {
          if (
            this.usageOperationSystems.length &&
            opSystemId ===
              parseInt(this.usageOperationSystems[i].operation_system_id)
          ) {
            this.usageOperationSystems.splice(i, 1);
          }
          if (this.usageOperationSystems.length === 1) {
            element.checked = true;
          }
        }
        this.tabActive = 1;
      } else if (!this.checkOpSystem(opSystemId)) {
        this.usageOperationSystems.push({
          operation_system_id: opSystemId,
          files: null,
          filesNew: [],
          info: [],
          description: ""
        });
        this.tabActive = opSystemId;
      }
    },
    resetFiles() {
      this.files = [];
    },
    showSystemOperation() {
      this.showSystemOp =
        this.usageChannels.length &&
        this.usageChannels.filter(channel => channel.channel_id === 3).length;
    },
    getChannelName(id) {
      const channels = ["Web+Wap", "SMS", "App"];
      return channels[id - 1];
    },
    getOpSystemlName(id) {
      const opSystems = ["ios", "android"];
      return opSystems[id - 1];
    },
    addVersionStore(opSystemId) {
      const appNewVersion = this.appNewVersion;
      this.usageOperationSystems.forEach(version => {
        if (version.operation_system_id === this.tabActive) {
          version.info.push(appNewVersion);
        }
      });
      this.appNewVersion = {
        id: "",
        version: "",
        build_link: "",
        store_link: "",
        description: ""
      };
      $("#app-version-store").modal("hide");
    },
    addFiles(element) {
      const qtdFiles = element.files.length;
      const id = element.getAttribute("data-id");
      const span = document.getElementById(`info-file-${id}`);
      if (qtdFiles) {
        for (let i = 0; i < qtdFiles; i++) {
          this.formData.append(`${id}[]`, element.files[i]);
        }
        span.innerHTML = `${qtdFiles} Arquivo${
          qtdFiles > 1 ? "s" : ""
        } selecionado`;
      } else {
        span.innerHTML = "Adicionar imagens";
      }
    },

    validator() {
      let isValid = true;

      console.log(this.service.restrictions_usage);
      if (
        this.service.restrictions_usage === true ||
        this.service.restrictions_usage === 1
      ) {
        if (
          !this.service.restrictions_description ||
          this.service.restrictions_description === ""
        ) {
          isValid = false;
        }
      }

      return isValid;
    },

    setFormDataSaved() {
      this.formData.append(
        "restrictions_usage",
        this.service.restrictions_usage
      );
      this.formData.append(
        "restrictions_description",
        this.service.restrictions_description
      );

      this.formData.append("step_by_step", this.service.step_by_step);
      this.formData.append("rules_of_use", this.service.rules_of_use);
      this.formData.append("description_tech", this.service.description_tech);
      this.formData.append("service_id", this.service.id);
      this.formData.append("channels", JSON.stringify(this.usageChannels));
      this.formData.append(
        "usageOperationSystems",
        JSON.stringify(this.usageOperationSystems)
      );
      const savedChannels = document.querySelectorAll(".usage-opsystem-saved");
      const savedFiles = document.querySelectorAll(".usage-files-saved");
      savedFiles.forEach((file, index) => {
        this.formData.append("savedFiles[]", file.value);
      });
      const savedAppInfo = document.querySelectorAll(".app-info-saved");
      savedAppInfo.forEach((app, index) => {
        this.formData.append("savedAppInfo[]", app.value);
      });
      const savedOpSystem = document.querySelectorAll(".usage-opsystem-saved");
      savedOpSystem.forEach((opSystem, index) => {
        this.formData.append("savedOpSystem[]", opSystem.value);
      });
    },
    checkOpSystem(opSystemId) {
      return (
        this.usageOperationSystems &&
        this.usageOperationSystems.length &&
        this.usageOperationSystems.filter(
          system => system.operation_system_id == opSystemId
        ).length
      );
    },
    checkChannel(channelId) {
      return (
        this.usageChannels &&
        this.usageChannels.length &&
        this.usageChannels.filter(channel => channel.channel_id == channelId)
          .length
      );
    },
    deleteFile(opSystemIndex, fileIndex) {
      this.usageOperationSystems[opSystemIndex].files.splice(fileIndex, 1);
    },
    clearData() {
      this.formData = new FormData();
      document.querySelectorAll(".all-files").forEach(file => {
        file.value = null;
      });
      document.querySelectorAll(".info-file").forEach(spam => {
        spam.innerHTML = "Adicionar imagens";
      });
    },
    navActive(item) {
      return item === this.tabActive;
    },
    dismissModal(id) {
      $(id).modal("hide");
    },
    setNewAppVersion(tabActive) {
      this.tabActive = tabActive;
      setTimeout(() => {
        this.$refs.appNewVersionInput.focus();
      }, 500);
    },
    lockForm(isLocked) {
      const form = $(this.$refs.form);

      if (isLocked) {
        form.find("input, textarea, select").prop("disabled", true);
      } else {
        form.find("input, textarea, select").prop("disabled", false);
      }
    },

    preventEditForm() {
      setTimeout(() => {
        if (this.service.status_register === "complete") {
          this.$store.commit("setLock", true);
          this.lockForm(true);
        }
      }, 0);
    }
  }
};
</script>

<style scoped>
.link {
  cursor: pointer;
}
.pl-8 {
  padding-left: 80px;
}
</style>
