<template>
	<div id="u-sub-header" class="m-subheader border-bottom pb-3">
			<div class="d-flex align-items-center">
				<div class="mr-auto">
					<h6 :class="`m-subheader__title ${title && breadcrumbs?'m-subheader__title--separator':''}`">{{ title }}</h6>
					<ul class="m-subheader__breadcrumbs m-nav m-nav--inline">
						<slot v-if="breadcrumbs" name="breadcrumbs"></slot>
					</ul>
				</div>
				<div>
					<slot name="action"></slot>
				</div>
			</div>
		</div>
</template>

<script>
export default {
	name: 'USubHeader',
	props: {
		title: {
			type: String,
		},
		breadcrumbs: {
			type: Boolean,
			default: false,
		}
	},
}
</script>

